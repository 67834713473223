/** cloud function api */
const cloudFunctionApi = process.env.REACT_APP_FUNCTIONS_API_URL;
/** fmcg cloud api **/
const fmcgCloudApi = process.env.REACT_APP_FMCG_API_URL;
const cloudV2Api = process.env.REACT_APP_API_V2_URL;
const fmcgApiAuth = process.env.REACT_APP_FMCG_API_URL_AUTH;
/** nimbly-cloud api */
const nimblyCloudApi = process.env.REACT_APP_CLOUD_API_URL;
const nimblyCloudApiAlt = process.env.REACT_APP_CLOUD_API_URL_ALT;
const nimblyBaseCloudApiAlt = process.env.REACT_APP_BASE_CLOUD_API_URL_ALT;
const nimblyAppengineURL = process.env.REACT_APP_CLOUD_API_APPENGINE_URL;
const nimblyAppengineInternalURL = process.env.REACT_APP_CLOUD_API_APPENGINE_INTERNAL_URL;
const nimblyReturnToURL = process.env.REACT_APP_ADVANCED_ANALYTICS_RETURNTO_URL;
const nimblyDashboardID = process.env.REACT_APP_ADVANCED_ANALYTICS_OVERVIEW_ID;
const nimblyDashboardIDForDemo = process.env.REACT_APP_ADVANCED_ANALYTICS_OVERVIEW_ID_DEMO;
const nimblyDashboardIDForIssueDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_ISSUE_DASHBOARD_ID;
const nimblyDashboardIDForIssueDashboardDemo = process.env.REACT_APP_ADVANCED_ANALYTICS_ISSUE_DASHBOARD_ID_DEMO;
const nimblyProdOverviewDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_PROD_OVERVIEW_ID;
const nimblyProdIssueDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_PROD_ISSUE_ID;
const nimblyDownloadManagerDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_DOWNLOAD_MANAGER_ID;
const nimblyProdURL = process.env.REACT_APP_PROD_URL;
const nimblyRankingDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_RANKING_DASHBOARD_ID;
const nimblyfnbInsightsDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_FNB_INSIGHTS_DASHBOARD_ID;
const nimblyStorePerformance = process.env.REACT_APP_ADVANCED_ANALYTICS_STORE_PERFORMANCE_DASHBOARD_ID;
const nimblyProdDMID = process.env.REACT_APP_ADVANCED_ANALYTICS_PROD_DOWNLOAD_MANAGER_ID;
const nimblyUsersDashboardID = process.env.REACT_APP_ADVANCED_ANALYTICS_USERS_DASHBOARD_ID;
const nimblyProdUserDashboardID = process.env.REACT_APP_ADVANCED_ANALYTICS_PROD_USERS_DASHBOARD_ID;
const nimblyIRFSATDashboardID = process.env.REACT_APP_ADVANCED_ANALYTICS_IRF_SAT_DASHBOARD_ID;
const nimbluIRFSATProdDashboardID = process.env.REACT_APP_ADVANCED_ANALYTICS_IRF_SAT_PROD_DASHBOARD_ID;
const nimblyStockAuditDashbaordID = process.env.REACT_APP_ADVANCED_ANALYTICS_STOCK_AUDIT_DASHBOARD_ID;
const nimblyStockAuditProdDashboardID = process.env.REACT_APP_ADVANCED_ANALYTICS_STOCK_AUDIT_PROD_DASHBOARD_ID;
const nimblySitesDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_SITE_DASHBOARD_ID;
const nimblyJJSitesDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_JANJIJIWA_SITE_DASHBOARD_ID;
const nimnlyProdSitesDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_PROD_SITE_DASHBOARD_ID;
const nimnlyProdJJSitesDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_PROD_JANJIJIWA_SITE_DASHBOARD_ID;
const nimblyNPSDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_NPS_DASHBOARD_ID;
const nimblyCustomerFeedbackDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_CUSTOMER_FEEDBACK_ID;
const nimblyLmsAdmin = process.env.REACT_APP_ADVANCED_ANALYTICS_LMS_ADMIN;
const nimblyLmsInstructor = process.env.REACT_APP_ADVANCED_ANALYTICS_LMS_INSTRUCTOR;
const nimblyLmsLearner = process.env.REACT_APP_ADVANCED_ANALYTICS_LMS_LEARNER;
const nimblyProdLmsAdmin = process.env.REACT_APP_ADVANCED_ANALYTICS_PROD_LMS_ADMIN;
const nimblyProdLmsInstructor = process.env.REACT_APP_ADVANCED_ANALYTICS_PROD_LMS_INSTRUCTOR;
const nimblyProdLmsLearner = process.env.REACT_APP_ADVANCED_ANALYTICS_PROD_LMS_LEARNER;
const nimblyCustomerMetricsDashboard = process.env.REACT_APP_ADVANCED_ANALYTICS_CUSTOMER_METRICS_DASHBOARD_ID;
const nimblyProdEnv = process.env.REACT_APP_PRODUCTION_ENV;
const nimblyThaiDashboardID = process.env.REACT_APP_ADVANCED_ANALYTICS_THAI_DASHBOARD_ID;
const nimblyKFCOverview = process.env.REACT_APP_KFC_OVERVIEW_DASHBOARD_ID;
const nimblyKFCIssues = process.env.REACT_APP_KFC_ISSUE_DASHBOARD_ID;
const nimblyKFCUsers = process.env.REACT_APP_KFC_USERS_DASHBOARD_ID;
const nimblyKFCSites = process.env.REACT_APP_KFC_SITES_DASHBOARD_ID;
const nimblyKFCDownloadManager = process.env.REACT_APP_KFC_DOWNLOAD_MANAGER_DASHBOARD_ID;
const nimblyPRODKFCOverview = process.env.REACT_APP_KFC_PROD_OVERVIEW_DASHBOARD_ID;
const nimblyPRODKFCIssues = process.env.REACT_APP_KFC_PROD_ISSUE_DASHBOARD_ID;
const nimblyPRODKFCUsers = process.env.REACT_APP_KFC_PROD_USERS_DASHBOARD_ID;
const nimblyPRODKFCDownloadManager = process.env.REACT_APP_KFC_PROD_DOWNLOAD_MANAGER_DASHBOARD_ID;
const nimblyPRODKFCSites = process.env.REACT_APP_KFC_PROD_SITES_DASHBOARD_ID;
const nimblyRMUOverview = process.env.REACT_APP_RMU_OVERVIEW_DASHBOARD_ID;
const nimblyProdRMUOverview = process.env.REACT_APP_RMU_PROD_OVERVIEW_DASHBOARD_ID;
const nimblyCEOPersona = process.env.REACT_APP_ADVANCED_ANALYTICS_CEO_PERSONA_DASHBOARD_ID;
const nimblyProdCEOPersona = process.env.REACT_APP_ADVANCED_ANALYTICS_PROD_CEO_PERSONA_DASHBOARD_ID;
const nimblyBulkOpsApiUrl = process.env.REACT_APP_BULK_OPS_API_URL;
const nimblyInternalURL = process.env.REACT_APP_INTERNAL_URL;

const UploadScheduleTemplate = process.env.REACT_APP_SCHEDULE_BULK_UPLOAD_TEMPLATE;
const CreateQuizTemplate = process.env.REACT_APP_LMS_QUIZ_BULK_CREATE_TEMPLATE;
const reportBulkDownload = process.env.REACT_APP_REPORT_BULK_DOWNLOAD;
const nimblyBulkUploadTemplate = process.env.REACT_APP_BULK_UPLOAD_TEMPLATE;

const departmentApi = nimblyCloudApi + '/departments';
const userApi = nimblyCloudApi + '/users';
const siteApi = nimblyCloudApi + '/sites';
const questionnaireApi = nimblyCloudApi + '/questionnaires';

const issuesApi = nimblyCloudApi + '/issues/issues';
const issuesAppEngineApi = nimblyCloudApi + '/app-engine/issues';
const issueMessageApi = nimblyCloudApi + '/issues/issueMessages';

export const baseURL = cloudFunctionApi;
export const apiURL = nimblyCloudApi;
export const fmcgURL = fmcgCloudApi;
export const fmcgAuth = fmcgApiAuth;
export const apiURLAlt = nimblyCloudApiAlt;
export const baseApiURLAlt = nimblyBaseCloudApiAlt;
export const cloudV2ApiURL = cloudV2Api;
export const appengineURL = nimblyAppengineURL;
export const appengineInternalURL = nimblyAppengineInternalURL;
export const returnToURL = nimblyReturnToURL;
export const dashboardID = nimblyDashboardID;
export const dashboardIDDemo = nimblyDashboardIDForDemo;
export const issueDashboardID = nimblyDashboardIDForIssueDashboard;
export const issueDashboardIDDemo = nimblyDashboardIDForIssueDashboardDemo;
export const prodOverviewDashboard = nimblyProdOverviewDashboard;
export const prodURL = nimblyProdURL;
export const prodIssueDashboard = nimblyProdIssueDashboard;
export const rankingDashboardID = nimblyRankingDashboard;
export const fnbInsightsDashboardID = nimblyfnbInsightsDashboard;
export const storePerformanceDashboardID = nimblyStorePerformance;
export const downloadManagerID = nimblyDownloadManagerDashboard;
export const prodDownloadManagerID = nimblyProdDMID;
export const usersDashbaordID = nimblyUsersDashboardID;
export const prodUserDashboardID = nimblyProdUserDashboardID;
export const irfsatDashboardID = nimblyIRFSATDashboardID;
export const irfsatProdDashbaordID = nimbluIRFSATProdDashboardID;
export const stockAuditDashboardID = nimblyStockAuditDashbaordID;
export const prodStockAuditDashboardID = nimblyStockAuditProdDashboardID;
export const sitesDashboardID = nimblySitesDashboard;
export const jjSitesDashboardID = nimblyJJSitesDashboard;
export const sitesProdDashboardID = nimnlyProdSitesDashboard;
export const jjSitesProdDashboardID = nimnlyProdJJSitesDashboard;
export const npsDashboardID = nimblyNPSDashboard;
export const customerFeedbackDashboardID = nimblyCustomerFeedbackDashboard;
export const cfDashboardID = nimblyCustomerFeedbackDashboard;
export const adminDashboardID = nimblyLmsAdmin;
export const learnerDashboardID = nimblyLmsLearner;
export const instructorDashboardID = nimblyLmsInstructor;
export const adminProdDashboardID = nimblyProdLmsAdmin;
export const learnerProdDashboardID = nimblyProdLmsLearner;
export const instructorProdDashboardID = nimblyProdLmsInstructor;
export const metricsDashboardID = nimblyCustomerMetricsDashboard;
export const thaiDashboardID = nimblyThaiDashboardID;
export const prodEnvUrl = nimblyProdEnv;
export const KFCOverviewDashboardID = nimblyKFCOverview;
export const KFCIssueDashboardID = nimblyKFCIssues;
export const KFCUserDashboardID = nimblyKFCUsers;
export const KFCSitesDashboardID = nimblyKFCSites;
export const KFCDMDashboardID = nimblyKFCDownloadManager;
export const KFCProdOverviewDashboardID = nimblyPRODKFCOverview;
export const KFCProdIssueDashboardID = nimblyPRODKFCIssues;
export const KFCProdUserDashboardID = nimblyPRODKFCUsers;
export const KFCProdDMDashboardID = nimblyPRODKFCDownloadManager;
export const KFCProdSitesDashboardID = nimblyPRODKFCSites;
export const RMUOverviewDashboardID = nimblyRMUOverview;
export const RMUProdOverviewDashboardID = nimblyProdRMUOverview;
export const CEOPersonaDashboardID = nimblyCEOPersona;
export const CEOPersonaProdDashboardID = nimblyProdCEOPersona;
export const bulkOpsUrl = nimblyBulkOpsApiUrl;
export const internalUrl = nimblyInternalURL;

export const NIMBLY_API_ISSUES_DETAIL = issuesApi + '/';
export const NIMBLY_API_ISSUES_DETAIL_V2 = `${issuesApi}/v2/`;
export const NIMBLY_API_ISSUES_LIST = issuesApi;
export const NIMBLY_API_ISSUES_LIST_V2 = NIMBLY_API_ISSUES_LIST + '/v2';
export const NIMBLY_API_ISSUES_LIST_V3 = NIMBLY_API_ISSUES_LIST + '/v3';
export const NIMBLY_API_ISSUES_CREATE = issuesApi + '/create';
export const NIMBLY_API_ISSUES_CREATE_V2 = NIMBLY_API_ISSUES_CREATE + '/v2';
export const NIMBLY_API_ISSUES_BULK_EDIT = issuesApi + '/bulkUpdate';
export const NIMBLY_API_ISSUES_BULK_MESSAGE = issueMessageApi + '/bulkCreate';
export const NIMBLY_API_ISSUES_UPDATE = issuesApi + '/update';
export const NIMBLY_API_ISSUES_COMMENT = issueMessageApi + '/create';
export const NIMBLY_API_ISSUES_COMMENT_V2 = NIMBLY_API_ISSUES_COMMENT + '/v2';
export const NIMBLY_API_ISSUES_UPLOAD_PHOTO = issueMessageApi + '/upload';
export const NIMBLY_API_ISSUES_CUSTOM_FILTER = nimblyCloudApi + '/issues/issue-tracker-filters';
export const NIMBLY_API_ISSUES_UPDATE_READ_STATUS = nimblyCloudApi + '/issues/issues/readStatus/bulk-update';

// Department
export const NIMBLY_API_DEPARTMENT_LIST = departmentApi;

// Site
export const NIMBLY_API_SITE_LIST_COMPACT = siteApi + '/compact';

// User
export const NIMBLY_API_USER_LIST = userApi;

// Questionnaire
export const NIMBLY_API_QUESTIONNAIRE_QUESTION_CATEGORY_LIST = questionnaireApi + '/questioncategories';

export const NIMBLY_API_ATTACHMENT_THUMBNAIL = process.env.REACT_APP_GALLERY_THUMBNAIL_URL;

export const GALLERY_API_URL = apiURLAlt + '/gallery/gallery';
export const GALLERY_DOWNLOAD_URL = process.env.REACT_APP_GALLERY_DOWNLOAD_URL + '/gallery/download';
export const GALLERY_THUMBNAIL_URL = process.env.REACT_APP_GALLERY_THUMBNAIL_URL;

export const FILE_REPO_API_URL = apiURLAlt + '/file-repo';

/** Reports Hub */
export const NIMBLY_API_ISSUES_TRIGGER_DOWNLOAD_MANAGER = issuesAppEngineApi + '/compiled-issues';
export const NIMBLY_API_ISSUES_COUNT_DOWNLOAD_MANAGER = issuesAppEngineApi + '/count';

// Set Password Manager
export const NIMBLY_DYNAMIC_LINK_SET_PASSWORD = 'https://nimbly.page.link/set-auth';
export const NIMBLY_AUDIT_LINK = 'https://audit.hellonimbly.com/';

// CS-utility which is currently being deployed with nps
export const NIMBLY_API_NPS_BASE = `${apiURL}/nps`;
export const NIMBLY_DELETE_MISSED_REPORT = `${NIMBLY_API_NPS_BASE}/deleteMissedReport`;
export const NIMBLY_FETCH_ALL_ORGSLIST = `${NIMBLY_API_NPS_BASE}/orgList`;
export const NIMBLY_FETCH_SITES_WITH_DETAILS = `${NIMBLY_API_NPS_BASE}/siteQuestionnaireAuditorList`;
export const NIMBLY_CHANGE_USER_EMAIL = `${NIMBLY_API_NPS_BASE}/changeEmailAddress`;
export const bulkUploadScheduleTemplate = UploadScheduleTemplate;
export const bulkCreateQuizTemplate = CreateQuizTemplate;
export const bulkReportDownloadURL = reportBulkDownload;
export const bulkUploadTemplateURL = nimblyBulkUploadTemplate;

/** Site Groups */
export const SITE_GROUPS_BASE_URL = `${apiURL}/sites/group`;

/** Vertex AI */
export const COMMENT_TRANSLATION_API = `${apiURL}/vertex-ai/translation/v1`;
