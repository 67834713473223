/* eslint-disable complexity */
import { store } from 'store/configStore';
import { apiURL } from 'config/baseURL';
import API from 'helpers/api';
import { IssueInsightsData, IssueTableList } from 'reducers/dashboardRevamp/issueInsights/issueInsightsStore';
import { IssuesListData } from 'components/downloadManager/DownloadManagerConfirmation';
import _ from 'lodash';
import { filterObjectWithEmptyValues } from 'pages/dashboardRevamp/utils/filterObjectWithEmptyValues';
import { getInternalToken } from 'services/bulkOpsRevamp/bulkEdit.service';

export const buildQueryParams = () => {
	const { filters } = store.getState().dashboardOperational;
	const globalFilters = _.cloneDeep(filters);
	// Don't include ManageBy filters
	delete globalFilters.managedByDeptIDs;
	delete globalFilters.managedByUserIDs;
	delete globalFilters.siteRegionIDs;
	const {
		limit,
		sortDirection,
		metric,
		viewBy,
		groupBy,
		groupByID,
		sortBy,
		period,
		status,
		page,
	} = store.getState().dashboardIssueInsights;
	const query = {
		...globalFilters,
		metric,
		viewBy,
		groupBy,
		groupByID,
		sortBy,
		limit,
		period,
		status,
		sortDirection,
		page,
	};

	// Issue insight summary
	if (metric === 'issue-insight-overview') {
		delete query.groupBy;
		delete query.period;
		delete query.viewBy;
		delete query.limit;
		delete query.page;
	}

	// Create a new object that excludes properties with "empty" values.
	const filteredData = filterObjectWithEmptyValues(query);

	return filteredData;
};

export const getRequestOptions = async (data: object, isInternalToken?: boolean) => {
	const firebaseToken = await API.getFirebaseToken();

	return {
		method: 'POST',
		headers: {
			Authorization: isInternalToken ? await getInternalToken(firebaseToken) : firebaseToken,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	};
};

const stringifyWithIndex = (drillBy?: string[]): string => {
	if (!drillBy) return '';
	return drillBy.map((value, index) => `drillBy[${index}]=${value}`).join('&');
};

const buildChartQueryParams = () => {
	const { filters } = store.getState().dashboardOperational;
	const { issueDetailsChartFilters, sortDirection, sortBy, status } = store.getState().dashboardIssueInsights;
	const customFilters = _.cloneDeep(issueDetailsChartFilters);
	let drillBy = '';
	if ((customFilters?.drillBy?.length ?? 0) > 1) {
		drillBy = stringifyWithIndex(customFilters?.drillBy);
	}
	if (drillBy === '') {
		return {
			...filters,
			...issueDetailsChartFilters,
			sortBy,
			sortDirection,
			status,
		};
	} else {
		const query = {
			...filters,
			...customFilters,
			sortBy,
			sortDirection,
			status,
		};
		return query;
	}
};

const fetchDashboardData = async (endpoint: string): Promise<any | undefined> => {
	const queryParams = buildQueryParams();
	const url = `${apiURL}/statistics/dashboard/${endpoint}`;
	const options = await getRequestOptions(queryParams);
	const res = await fetch(url, options);
	const responseData = await res.json();

	return responseData?.data?.issueList || responseData?.data?.irrTimeArray || responseData?.data;
};

export const fetchIssueInsightDetails = async (): Promise<IssueInsightsData | undefined> => {
	return fetchDashboardData('issue/detail');
};

export const fetchIssueInsightList = async (): Promise<IssuesListData[] | undefined> => {
	return fetchDashboardData('issue');
};

export const fetchIssueInsightChartDetails = async (): Promise<IssuesListData[] | undefined> => {
	const queryParams = buildChartQueryParams();
	const url = `${apiURL}/statistics/dashboard/issue`;
	const options = await getRequestOptions(queryParams);
	const res = await fetch(url, options);
	const responseData = await res.json();

	return responseData?.data?.issueList || responseData?.data?.irrTimeArray || responseData?.data;
};

export const fetchIssueInsightTableList = async (): Promise<IssueTableList | undefined> => {
	const queryParams = buildQueryParams();
	const url = `${apiURL}/statistics/dashboard/issue`;
	const options = await getRequestOptions(queryParams);
	const res = await fetch(url, options);
	const responseData = await res.json();

	return responseData?.data;
};
